.not-found {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.not-found__title {
  text-align: center;
  margin-bottom: 20px;
}

.not-found__title span,
.not-found__title b {
  display: block;
}

.not-found__title b {
  margin-top: 60px;
  color: #574f69;
}

.not-found__title span {
  font-size: 150px;
  color: #158deb;
}

.not-found__link {
  text-align: center;
  text-transform: uppercase;
  display: block;
  width: fit-content;
  margin: auto;
  margin-top: 10%;
  padding: 14px 50px 12px;
  background: #158deb;
  color: #ffffff;
}
