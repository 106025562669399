
.entrance {
  position: relative;
  min-height: 100vh;
  background-color: #574f69;
}
.entrance__wrapper {
  position: absolute;
  max-width: 500px;
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 50px;
  text-align: center;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.entrance__title {
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .entrance__title {
    font-size: 14px;
    line-height: 19px;
  }
}
.entrance__button {
  padding: 20px 78px;
}
@media (hover: hover) and (pointer: fine) {
  .entrance__button:hover:not(.focus-visible) {
    background-color: #17c1bc;
  }
}

.entrance__invalid-text {
  position: absolute;
  left: 15%;
  top: -20%;
  color: #e86969;
  font-size: 11px;
  text-transform: uppercase;
  opacity: 0;
  -webkit-transition: opacity 0.6s ease-out;
  -o-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}
